<template>
    <div id="add-customer" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel2">New Customer</h4>
                    <button ref="add_customer_close_button" type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
                <div class="modal-body">
                    <form class="form-horizontal form-element">
                        <div class="col-md-12 mb-20">
                            <p class="text-danger" v-if="error">{{errorMessage}}</p>
                        </div>
                        <div class="col-md-12 mb-20">
                            <div class="form-group">
                                <label>Select Customer Type</label>
                                <select v-model="customerType" @change="onCustomerTypeChange"  class="form-control select2">
                                  <option value="individual" selected>Individual</option>
                                  <option value="business">Business</option>
                                </select>
                                <p class="text-danger" v-if="customerTypeError">{{customerTypeErrorMessage}}</p>
                            </div>
                            <div v-if="individualData">
                              <div class="form-group">
                                <input v-model="firstName" type="text" class="form-control" placeholder="First Name">
                                <p class="error" v-if="firstNameError">{{firstNameErrorMessage}}</p>
                              </div>
                              <div class="form-group">
                                <input v-model="lastName" type="text" class="form-control" placeholder="Last Name">
                                <p class="error" v-if="lastNameError">{{lastNameErrorMessage}}</p>
                              </div>
                            </div>
                            <div v-if="businessData">
                              <div class="form-group">
                                <input v-model="businessName" type="text" class="form-control" placeholder="Business Name">
                                <p class="error" v-if="businessNameError">{{businessNameErrorMessage}}</p>
                              </div>
                            </div>
                            
                            <div class="form-group">
                                <input v-model="email" type="text" class="form-control" placeholder="Email">
                                <p class="error" v-if="emailError">{{emailErrorMessage}}</p>
                            </div>
                            <div class="form-group">
                                <input v-model="phone" type="text" class="form-control" placeholder="Phone">
                                <p class="error" v-if="phoneError">{{phoneErrorMessage}}</p>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info" @click="saveNewCustomer">Save<span v-if="loading" class="spinner-border text-white"></span></button>
                    <button type="button" class="btn btn-default float-right" data-dismiss="modal">Cancel</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
</template>

<script>
import $ from 'jquery'
import { repository } from '../presenter'
export default {
  name: 'NewCustomer',
  mounted() {
    const sweetAlert = document.createElement('script')
    sweetAlert.setAttribute('src', '/assets/styles/vendor_components/sweetalert/sweetalert.min.js')
    document.head.appendChild(sweetAlert)
  },
  data () {
    return {
      customerType: 'individual',
      customerTypeError: false,
      customerTypeErrorMessage: '',
      individualData: true,
      businessData: false,
      
      email: '',
      emailError: false,
      emailErrorMessage: '',
      firstName: '',
      firstNameError: false,
      firstNameErrorMessage: '',
      lastName: '',
      lastNameError: false,
      lastNameErrorMessage: '',

      businessName: '',
      businessNameError: false,
      businessNameErrorMessage: '',

      phone: '',
      phoneError: false,
      phoneErrorMessage: '',
      
      generalError: false,
      generalErrorMessage: '',
      loading: false,
      success: false,
      successMessage: '',
      error: false,
      errorMessage: ''
    }
  },
  methods: {
    onCustomerTypeChange(event){
      if (this.customerType=='individual'){
        this.businessData=false;
        this.individualData=true;
        this.resetBusinessData();
      }else if (this.customerType=='business'){
        this.businessData=true;
        this.individualData=false;
        this.resetIndividualData();
      }else{
        this.customerTypeError=true;
        this.customerTypeErrorMessage='Select A Customer Type';
      }
    },

    resetBusinessData() {
      this.businessName='';
    },
    resetIndividualData() {
      this.firstName='';
      this.lastName='';
    },


    async saveNewCustomer () {
      if (this.loading) return
      this.resetData()
      const newCustomerDataValidation = this.validateNewCustomerData()
      if (!newCustomerDataValidation.success) return
      this.loading = true
      const data = {
        customerType: this.customerType,
        email: this.email,
        phone: this.phone,
      }
      if (this.customerType=='individual'){
        data.first_name = this.firstName;
        data.last_name = this.lastName;
      }else if (this.customerType=='business'){
        data.business_name = this.businessName;
      }else{
        throw("invalid customer type");
      }
      const response = await repository.user.createNewCustomer(data)
      this.loading = false
      if (response.success) {
        this.resetData()
        this.showSuccessMessage('Customer Created');
        return
      }
      this.showErrorMessage(response.data)
    },

    validateNewCustomerData () {
      const returnValue = new Object()
      
      switch (this.customerType){
        case 'individual':
          if (!this.firstName) {
            this.firstNameError = true
            this.firstNameErrorMessage = 'Enter a valid first name'
            returnValue.success = false
            return returnValue
          }
          if (!this.lastName) {
            this.lastNameError = true
            this.lastNameErrorMessage = 'Enter a valid last name'
            returnValue.success = false
            return returnValue
          }
          break;
        case 'business':
          if (!this.businessName) {
            this.businessNameError = true
            this.businessNameErrorMessage = 'Enter a valid business name'
            returnValue.success = false
            return returnValue
          }
          break;
      }

      if (!this.email) {
        this.emailError = true
        this.emailErrorMessage = 'Enter a valid email'
        returnValue.success = false
        return returnValue
      }
      
      if (!this.phone) {
        this.phoneError = true
        this.phoneErrorMessage = 'Enter a valid phone number'
        returnValue.success = false
        return returnValue
      }
      returnValue.success = true
      return returnValue
    },

    showSuccessMessage (message) {
      swal(message, "", "success")
      this.$refs.add_customer_close_button.click();
    },
    showErrorMessage (message) {
      this.error = true
      this.errorMessage = message
    },

    resetData () {
      this.generalError = false
      this.emailError = false
      this.firstNameError = false
      this.businessNameError = false
      this.lastNameError = false
      this.roleError = false
      this.success = false
      this.successMessage = ''
      this.error = false
      this.errorMessage = ''
    }
  }
}
</script>

<style scoped>
@import url('/assets/styles/vendor_components/sweetalert/sweetalert.css');
</style>

